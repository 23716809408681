import { Link } from "gatsby";
import * as React from "react";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";

interface ListCardProps {
    title: string;
    titleExtra?: string;
    extraInfo?: string;
    excerpt: string;
    linkText?: string;
    linkPath?: string;
    fullHeight?: boolean;
}

const ListCard: React.FunctionComponent<ListCardProps> = ({
    title,
    titleExtra,
    extraInfo,
    excerpt,
    linkText,
    linkPath,
    fullHeight
}) => (
    <Card className={fullHeight ? "my-3 h-100" : "my-3"}>
        <CardBody>
            <Row className="mb-4">
                <Col sm={6} lg={8}>
                    <h2 className="h4">{title}</h2>
                </Col>

                <Col sm={6} lg={4} className="list-card-title-extra">
                    {titleExtra && (
                        <div className="text-muted">{titleExtra}</div>
                    )}
                </Col>
            </Row>
            {extraInfo && (
                <CardText className="font-italic">{extraInfo}</CardText>
            )}

            <CardText>{excerpt}</CardText>

            {linkPath && linkText && <Link to={linkPath}>{linkText}</Link>}
        </CardBody>
    </Card>
);

export default ListCard;
