import * as React from "react";
import { Row, Col } from "reactstrap";
import ListCard from "../listCard";

const ContractsList: React.FunctionComponent = () => (
    <Row>
        <Col lg={6} className="mb-3">
            <ListCard
                title="GSA Multiple Award Schedule (MAS)"
                excerpt="GSA MAS is a long-term governmentwide contract with commercial firms
                providing federal, state, and local government buyers access to more
                than 11 million commercial supplies (products) and services at
                volume discount pricing. SIN 54151S Information Technology
                Professional Services provides a broad range of IT services spanning
                the full spectrum of the system lifecycle. It offers federal, state,
                and local governments innovative solutions for their IT needs."
                fullHeight
            />
        </Col>

        <Col lg={6} className="mb-3">
            <ListCard
                title="ITES-3S"
                excerpt="The Information Technology Enterprise Solutions
                        - 3 Services (ITES-3S) contract is an Indefinite
                        Delivery, Indefinite Quantity (IDIQ) contract
                        vehicle structured to provide the full range of
                        IT services and solutions to the federal
                        government. ITES-3S is managed by the Computer
                        Hardware, Enterprise Software and Solutions
                        (CHESS) program at Ft. Belvoir, VA."
                linkPath="/contract-vehicles/ites-3s"
                linkText="More Info"
                fullHeight
            />
        </Col>

        <Col lg={6}>
            <ListCard
                title="DISA SETI"
                excerpt="SETI is a contract vehicle that has a
                        focused scope to provide new,
                        non-commodity and innovative information
                        technology engineering solutions,
                        embracing cutting-edge technologies
                        and methodologies for mission partners
                        across the Department of Defense (DoD).
                        SETI is a multiple award,
                        indefinite-delivery/indefinite-quantity
                        (IDIQ) vehicle designed for current
                        complex and unique requirements, but also
                        built to be technology-agnostic in order
                        to solve capability gaps in all future
                        mission needs."
                linkPath="/contract-vehicles/disa-seti"
                linkText="More Info"
                fullHeight
            />
        </Col>
    </Row>
);

export default ContractsList;
