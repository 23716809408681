import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import ContractsList from "../../components/contract-vehicles/contractsList";
import Layout from "../../components/layout";
import ListCard from "../../components/listCard";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import contractVehicles from "../../images/capitol-2.jpg";

class ContractVehicles extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Contract Vehicles" />

                <PageTitle
                    title="Contract Vehicles"
                    imageUrl={contractVehicles}
                />

                <ContentSection>
                    <ContractsList />
                </ContentSection>
            </Layout>
        );
    }
}

export default ContractVehicles;
